export default class Accordion{
  constructor(element) {
    this.element = element;
    this.elements = this.element.querySelectorAll('.js-header');

    this.init();
  }

  init() {
    this.setOptions();
    this.elements.forEach((element) => {
      element.addEventListener('click', this.toggleAccordion.bind(this));
    });
  }

  setOptions() {
    this.elements.forEach((element) => {
      if ('autoOpen' in element.dataset) {
        element.classList.add('is-active');
      }
    });
  }

  toggleAccordion(event) {
    const element = event.currentTarget;
    const isActive = element.classList.contains('is-active');

    this.elements.forEach((elementSelectionner) => {
      if (elementSelectionner === element) {
        if (!isActive) {
          elementSelectionner.classList.add('is-active');
        } else {
          elementSelectionner.classList.remove('is-active');
        }
      } else if ('notClosing' in this.element.dataset) {
        // Ne rien faire si 'notClosing' est défini
      } else {
        elementSelectionner.classList.remove('is-active');
      }
    });
  }
}


