export default class Modale {
    constructor(element) {
        this.element = element;
        this.modaleImage = document.getElementById('modaleImage');
        this.closeButton = this.element.querySelector('.close-modale');

        this.init();
    }

    init() {
        document.querySelectorAll('.open-modale').forEach(img => {
            img.addEventListener('click', (event) => {
                if (window.innerWidth > 768) { // Bloque la modale si la résolution est 375px ou moins
                    this.openModale(event);
                }
            });
        });

        this.closeButton.addEventListener('click', () => this.closeModale());

        this.element.addEventListener('click', (event) => {
            if (event.target === this.element && window.innerWidth > 375) {
                this.closeModale();
            }
        });
    }

    openModale(event) {
        const imageSrc = event.target.getAttribute('data-image');
        this.modaleImage.src = imageSrc;
        this.element.style.display = 'block';
    }

    closeModale() {
        this.element.style.display = 'none';
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const modale = new Modale(document.getElementById('imageModale'));
});
