export default class IndexCarousel {
  constructor(element) {
      this.element = element;
      this.slider = this.element.querySelector('.slider');
      this.items = this.element.querySelectorAll('.item');
      this.init();
  }

  init() {
      // Attacher les événements de clic uniquement aux boutons "prev" et "next"
      this.element.querySelector('.next').addEventListener('click', () => this.next());
      this.element.querySelector('.prev').addEventListener('click', () => this.prev());
  }

  next() {
      // Faire défiler l'élément à gauche (élément en tête vers la fin)
      this.slider.append(this.items[0]);
      // Mettre à jour la collection d'items
      this.items = this.element.querySelectorAll('.item');
  }

  prev() {
      // Faire défiler l'élément à droite (dernier élément vers le début)
      this.slider.prepend(this.items[this.items.length - 1]);
      // Mettre à jour la collection d'items
      this.items = this.element.querySelectorAll('.item');
  }
}
