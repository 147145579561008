import Swiper from 'swiper/bundle';

export default class Carousel {
    /**
     * constructor a des variables
     * @param {this} element - contient un element html
     * @param {this} options - contient les options des carousels
     * 
     */
  constructor(element) {
    this.element = element;
    this.options = {
      breakpoints: {
        768: {
          slidesPerView: 2.5,
        },
        1024: {
          slidesPerView: 2,
        },
      },
      direction: 'horizontal',
      spaceBetween: 20,
      pagination: {
        el: this.element.querySelector('.swiper-pagination'),
      },
      navigation: {
        nextEl: this.element.querySelector('.swiper-button-next'),
        prevEl: this.element.querySelector('.swiper-button-prev'),
      },
      loop: false,
    };
    this.init();
  }

    /**
     * init recupere les swiper des carousels et envoie des options au carousels si il en a besoin
     */
  init() {
    this.setOptions();
    new Swiper(this.element, this.options);
  }

    /**
     * setOptions donne des options au swiper si ils ont les data ci dessous et gere egalement le responsive 
     */
  setOptions() {
    this.variant = document.querySelectorAll('[data-variant="variant"]');
    if ((this.options.breakpoints = {})) {
    }

    if (this.element.dataset.variant == 'split') {
      this.options.breakpoints = {
        375: {
          spaceBetween: 30,
          slidesPerView: 1.5,
        },
        768: {
          slidesPerView: 2.5,
        },
        1024: {
          slidesPerView: 3.5,
        },
      };
    }
    if (this.element.dataset.variant == 'direction') {
      this.options.direction = 'vertical';
      this.options.spaceBetween = 30;
    }
    if ('autoplay' in this.element.dataset) {
      this.options.autoplay = {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      };
    }
    if ('gap' in this.element.dataset) {
      this.options.spaceBetween = parseInt(this.element.dataset.gap);
      this.options.slidesPerView = 2;
    }

    if (this.element.dataset.loop == 'true') {
      this.options.loop = true;
    }
    if (this.element.dataset.variant == '4') {
      this.options.breakpoints = {
        375: {
          spaceBetween: 30,
          slidesPerView: 1,
        },
        768: {
          spaceBetween: 20,
          slidesPerView: 2,
        },
      };
    }
  }
}
