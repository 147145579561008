export default class Loading {
  constructor(
    loadingElement,
    contentElement,
    originalBackgroundElement,
    particles
  ) {
    this.loadingElement = loadingElement;
    this.contentElement = contentElement;
    this.originalBackgroundElement = originalBackgroundElement;
    this.particles = particles;

    this.init();
  }

  init() {
    window.addEventListener("load", () => this.handleLoad());
    this.hideHeader(); // Cacher le header au début
  }

  handleLoad() {
    setTimeout(() => {
      this.loadingElement.style.display = "none";
      this.contentElement.style.display = "block";
      this.showOriginalBackground();
      this.showHeader(); // Afficher le header
      this.destroyParticles();

      // Permettre le défilement
      document.body.style.overflow = "auto";
    }, 2000);
  }

  hideHeader() {
    const header = document.querySelector("header");
    if (header) {
      header.style.display = "none";
    }
  }

  showHeader() {
    const header = document.querySelector("header");
    if (header) {
      header.style.display = "flex";
    }
  }

  showOriginalBackground() {
    this.originalBackgroundElement.style.display = "block";
  }

  destroyParticles() {
    this.particles.forEach((particle) => {
      particle.parentNode.removeChild(particle);
    });
  }
}

document.addEventListener("DOMContentLoaded", () => {
  const loadingScreen = document.getElementById("loading-screen");
  const content = document.getElementById("content");
  const originalBackground = document.getElementById("original-background");
  const particlesForeground = document.getElementById("particles-foreground");
  const particlesBackground = document.getElementById("particles-background");

  if (loadingScreen && content) {
    const loadingInstance = new Loading(
      loadingScreen,
      content,
      originalBackground,
      [particlesForeground, particlesBackground]
    );
  }
});
