export default class Scrolly {
  constructor(element) {
    this.element = element;
    this.options = {
      rootMargin: '0px 0px 0px 0px',
    };
    this.init();
  }

  init() {
    const observer = new IntersectionObserver(
      this.watch.bind(this),
      this.options
    );
    const items = this.element.querySelectorAll('[data-scrolly]');
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      observer.observe(item);
    }
  }

  watch(entries) {
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      const target = entry.target;
      const noRepeat = target.dataset.noRepeat !== undefined;

      if (entry.isIntersecting) {
        if (!noRepeat || !target.classList.contains('is-active')) {
          target.classList.add('is-active');

          if (target.dataset.scrolly === 'imageZoom') {
            target.style.transform = 'scale(1)';
            target.style.opacity = '1';
          }

          // Ajout de l'effet pour le dropdown
          if (target.dataset.scrolly === 'dropdown') {
            target.style.maxHeight = '200px'; // Définit la hauteur maximale pour afficher le contenu
            target.style.opacity = '1';
            target.style.visibility = 'visible';
            target.style.transition = 'max-height 3s ease-in, opacity 2s ease-in';
          }
        }
      } else if (noRepeat && target.classList.contains('is-active')) {
        return;
      } else if (target.dataset.noRepeat === '' && target.classList.contains('is-active')) {
        target.classList.remove('is-active');

        if (target.dataset.scrolly === 'imageZoom') {
          target.style.transform = 'scale(1.5)';
          target.style.opacity = '0';
        }

        // Réinitialisation de l'effet pour le dropdown
        if (target.dataset.scrolly === 'dropdown') {
          target.style.maxHeight = '0'; // Masque le contenu
          target.style.opacity = '0';
          target.style.visibility = 'hidden';
        }
      }
    }
  }
}
