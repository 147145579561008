import Scrolly from './components/Scrolly';
import Carousel from './components/Carousel';
import Youtube from './components/Youtube';
import Form from './components/Form';
import Header from './components/Header';
import Accordion from './components/Accordion';
import Modale from './components/Modale';
import Loading from './components/loading'
import index_carousel from './components/index_carousel';

export default class ComponentFactory {
  constructor() {
    this.componentList = {
      Scrolly,
      Carousel,
      Header,
      Youtube,
      Form,
      Accordion,
      Modale,
      Loading,
      index_carousel,
    };
    this.init();
  }

  init() {
    const components = document.querySelectorAll('[data-component]');

    for (let i = 0; i < components.length; i++) {
      const element = components[i];
      const componentName = element.dataset.component;

      if (this.componentList[componentName]) {
        new this.componentList[componentName](element);
      } else {
        console.log(`La composante ${componentName} n'existe pas!`);
      }
    }
  }
}
